export enum UserRole {
  SOLE_DIRECTOR = 'SOLE_DIRECTOR',
  DIRECTOR = 'DIRECTOR',
  SECRETARY = 'SECRETARY',
  SOLE_TRADER = 'SOLE_TRADER',
  PARTNERSHIP = 'PARTNERSHIP',
  TRUSTEE = 'TRUSTEE',
  OWNER = 'OWNER',
  OTHER = 'OTHER',
}

export enum CompanyDirectorsStructure {
  SOLE_DIRECTOR = 'SOLE_DIRECTOR',
  MULTI_DIRECTORS = 'MULTI_DIRECTORS',
}

export enum UserStatus {
  UNVERIFIED = 'UNVERIFIED',
  LIVE = 'LIVE',
}

export enum UserGroupRelation {
  TRUSTEE = 'TRUSTEE',
  OFFICER = 'OFFICER',
  EMPLOYEE = 'EMPLOYEE',
  OWNER = 'OWNER',
  TRUST_OWNER = 'TRUST_OWNER',
  UNSPECIFIED = 'UNSPECIFIED',
}

export enum UserGroupType {
  BUSINESS = 'BUSINESS',
  INDIVIDUALS = 'INDIVIDUALS',
}
