import { useMemo } from 'react';

// Supported icon name
export type SVGIconName =
  | 'placeholder'
  | 'create'
  | 'remove'
  | 'processing'
  | 'edit'
  | 'drop'
  | 'close'
  | 'close-circle'
  | 'view'
  | 'print'
  | 'search'
  | 'previous'
  | 'next'
  | 'previous-2'
  | 'next-2'
  | 'upload'
  | 'loading'
  | 'draft'
  | 'swap'
  | 'show-more'
  | 'delete'
  | 'download'
  | 'copy'
  | 'quick-actions'
  | 'claim-payment'
  | 'create-variation'
  | 'help'
  | 'link'
  | 'split-payment'
  | 'add-payment'
  | 'connect'
  | 'action-item'
  | 'dollar'
  | 'percentage'
  | 'target-stage'
  | 'collapse'
  | 'video-add'
  | 'photo-add'
  | 'photos-add'
  | 'documents-add'
  | 'pdf'
  | 'document-add'
  | 'video'
  | 'photo'
  | 'document'
  | 'documents'
  | 'agreement'
  | 'location'
  | 'personal'
  | 'shield'
  | 'star'
  | 'briefcase'
  | 'variation'
  | 'mobile'
  | 'email'
  | 'me'
  | 'business'
  | 'datetime'
  | 'checkbox'
  | 'project'
  | 'stage-deposit'
  | 'stage-base'
  | 'stage-frame'
  | 'stage-lockup'
  | 'stage-fixing'
  | 'stage-final'
  | 'stage-custom'
  | 'agreement-building'
  | 'agreement-subcontract'
  | 'agreement-supplier'
  | 'info'
  | 'key-info'
  | 'videos'
  | 'photos'
  | 'videos-2'
  | 'connected-payment'
  | 'menu'
  | 'menu-vertical'
  | 'notification'
  | 'agreement-building-lg'
  | 'agreement-subcontract-lg'
  | 'agreement-supplier-lg'
  | 'agreement-existing-lg'
  | 'variation-lg'
  | 'payment-in-lg'
  | 'placeholder-project'
  | 'placeholder-individual'
  | 'placeholder-business'
  | 'placeholder-acme'
  | 'people'
  | 'agreements'
  | 'payments'
  | 'timeframes'
  | 'other-info'
  | 'attachments'
  | 'settings'
  | 'profile'
  | 'dashboard'
  | 'add'
  | 'minus'
  | 'union'
  | 'reserved'
  | 'held'
  | 'zoom-in'
  | 'zoom-out'
  | 'linked'
  | 'secured'
  | 'links'
  | 'paid'
  | 'line'
  | 'new-pdf'
  | 'jpg'
  | 'png'
  | 'alert'
  | 'send'
  | 'success'
  | 'payment-paid'
  | 'payment-linked'
  | 'inbound'
  | 'outbound'
  | 'debit'
  | 'credit'
  | 'grid'
  | 'map-pin'
  | 'fast-forward'
  | 'droplet'
  | 'electricity'
  | 'gas'
  | 'wifi'
  | 'check'
  | 'minimize'
  | 'quick-start'
  | 'caution'
  | 'calendar'
  | 'dispensed'
  | 'early-release';

const defaultSVGIconProps = {
  color: 'currentColor',
  size: '1em',
};

export interface SVGIconProps {
  name: SVGIconName;
  size?: string | number;
  color?: string;
  twoToneColor?: string;
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}

export const SVGIcon: React.FC<SVGIconProps> = (props) => {
  const {
    name,
    size = defaultSVGIconProps.size,
    color = defaultSVGIconProps.color,
    twoToneColor,
    className: classNameProp,
    style,
    onClick,
  } = props;

  const className = useMemo(() => {
    return classNameProp ? `icon icon-${name} ${classNameProp}` : `icon icon-${name}`;
  }, [classNameProp, name]);

  return (
    <svg
      aria-hidden="true"
      color={twoToneColor ?? color}
      fill={color}
      width={size}
      height={size}
      className={className}
      style={style}
      onClick={onClick}
    >
      <use xlinkHref={`#icon-${name}`} />
    </svg>
  );
};

SVGIcon.displayName = 'SVGIcon';
