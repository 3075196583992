import { errorMessages, type ErrorStatusCode } from '@paid-ui/constants';
import { browserEnvs } from '@paid-ui/env';
import { uriPattern } from '@paid-ui/regexps';
import type { API } from '@paid-ui/types';
import isEmpty from 'lodash/isEmpty';

import { ensureQueryString } from './api-routes';

const { apiBaseUrl } = browserEnvs;

export interface RequestOptions extends Omit<RequestInit, 'body'> {
  /** Query string of request */
  query?: Record<string, any>;
  /** Payload of POST/PUT request */
  body?: Record<string, any>;
  /** Should request with token or not, default `true` */
  withToken?: boolean;
  /** Should request use next.js api routes */
  useApiRoutes?: boolean;
  /** Response type of fetch */
  responseType?: 'json' | 'blob';
  /** Ensure to get refresh token once (internal use by itself) */
  _hasTokenRefreshed?: boolean;
  /** Return bad request */
  returnBadRequest?: boolean;
}

const defaultRequestOptions = {
  withToken: false,
  useApiRoutes: false,
  responseType: 'json' as 'json' | 'blob',
  _hasTokenRefreshed: false,
} as RequestOptions;

const fixUrlSearchParams = (url: URL) => {
  return encodeURIComponent(decodeURIComponent(url.search.replace(/^\?/, '')))
    .replaceAll('%3D', '=')
    .replaceAll('%26', '&');
};

/**
 * Request util for data fetching.
 *
 * @param url - Relative request url without api base url
 * @param options - Request options
 * @returns Response data
 */
export async function request<T = API.Response | Blob>(
  url: string,
  options: RequestOptions = defaultRequestOptions,
): Promise<T> {
  try {
    const accessToken = sessionStorage.getItem('access_token');
    const {
      query,
      body,
      headers: headersOptions,
      _hasTokenRefreshed,
      withToken,
      useApiRoutes,
      responseType,
      returnBadRequest,
      ...restOptions
    } = options;

    let input = url;
    const baseUrl = useApiRoutes ? `${window.location.origin}/api` : apiBaseUrl;

    if (!uriPattern.test(url)) {
      input = baseUrl.endsWith('/') ? [baseUrl, url.slice(1)].join('') : [baseUrl, url].join('');
    }

    const _url = new URL(input);

    if (query && !isEmpty(query)) {
      for (const key in query) {
        const value = ensureQueryString(query[key]);
        if (value) {
          _url.searchParams.set(key, value);
        }
      }
    }

    const encodedUrl = new URL(_url.pathname, _url.origin).toString();
    const encodedSearchParams = fixUrlSearchParams(_url);
    input = encodedSearchParams ? `${encodedUrl}?${encodedSearchParams}` : encodedUrl;

    if (withToken && !accessToken) {
      throw new Error("Can't read token from sessionStorage.");
    }

    const headers = new Headers(headersOptions);

    headers.set('Content-Type', 'application/json');

    if (withToken) {
      headers.set('Authorization', `Bearer ${accessToken}`);
    }

    const response = await fetch(input, {
      ...restOptions,

      headers,
      body: body ? JSON.stringify(body) : undefined,
    });

    if (!response) {
      throw new Error('No response from server.');
    }

    if (!response.ok) {
      switch (response.status) {
        case 401: {
          if (!withToken) {
            const json = (await response.json()) as any;
            throw new Error(json?.message ?? errorMessages[response.status as ErrorStatusCode]);
          }

          if (_hasTokenRefreshed) {
            sessionStorage.removeItem('access_token');
            window.location.href = '/signin';
          } else {
            try {
              const _response = await fetch(`${baseUrl}/auth/refresh-token`, {
                method: 'POST',
                body: JSON.stringify({
                  access_token: accessToken,
                }),
                headers: {
                  'Content-Type': 'application/json',
                },
              });

              if (_response.ok) {
                const { data: tokens } = (await _response.json()) as API.RefreshTokenResponse;

                if (tokens?.access_token) {
                  sessionStorage.setItem('access_token', tokens.access_token);

                  return await request<T>(url, {
                    ...options,
                    _hasTokenRefreshed: true,
                  });
                }
              }

              sessionStorage.removeItem('access_token');
              window.location.href = '/signin';
            } catch {
              sessionStorage.removeItem('access_token');
              window.location.href = '/signin';
            }
          }
          break;
        }

        case 400:
        case 403:
        case 404:
        case 405:
        case 406:
        case 408:
        case 409:
        case 410:
        case 413:
        case 415:
        case 422:
        case 423:
        case 429:
        case 451:
        case 500:
        case 501:
        case 502:
        case 503:
        case 504: {
          const contentType = response.headers.get('content-type');

          if (contentType?.toLowerCase() === 'application/json') {
            const json = (await response.json()) as any;

            if (returnBadRequest) {
              return json;
            }

            throw new Error(json?.message ?? errorMessages[response.status as ErrorStatusCode]);
          } else {
            throw new Error(errorMessages[response.status as ErrorStatusCode]);
          }
        }

        default: {
          throw new Error('Internal server error.');
        }
      }
    }

    try {
      if (responseType === 'blob') {
        // @ts-expect-error type missing
        return await response.blob();
      }

      return (await response.json()) as T;
    } catch {
      return {} as T;
    }
  } catch (error) {
    if (error instanceof Error && error.name === 'AbortError') {
      throw new Error('Request timeout, aborted.');
    }

    throw error;
  }
}
