import { defaultAddress, emptyTrustee, PartyRole, PartyType, TrustType } from '@paid-ui/constants';
import { ContractRole, ContractType } from '@paid-ui/enums/contract';
import { PayType } from '@paid-ui/enums/party';
import type {
  AdditionalTrustee,
  HeadContract,
  Participant,
  ParticipantParty,
  Party,
  Subcontract,
  SupplyContract,
  UserDetail,
} from '@paid-ui/types';
import { type ContractParticipant } from '@paid-ui/types/parties';
import { utc2Mel } from '@paid-ui/utils/datetime';
import { isEmpty, sortBy } from 'lodash';

const unspecifiedPartyName = 'Unspecified party';

/**
 * Get all participants' formatted full name
 *
 * @param parties - Participant object list
 * @param type - Party type to filter
 * @returns Formatted full names
 */
export function getPartiesName(parties?: Participant[], type?: PartyType): string {
  if (!parties) {
    return unspecifiedPartyName;
  }

  let filteredParties = parties ?? [];

  if (type === PartyType.PAYER) {
    filteredParties = parties.filter((party) =>
      [PartyType.PAYER, PartyType.ADDITIONAL_PAYER].includes(party.partyType),
    );
  }

  if (type === PartyType.PAYEE) {
    filteredParties = parties.filter((party) =>
      [PartyType.PAYEE, PartyType.ADDITIONAL_PAYEE].includes(party.partyType),
    );
  }

  if (filteredParties.length === 0) {
    return unspecifiedPartyName;
  }

  const partyWithTrust = filteredParties.find(
    (party) => party.user?.trust?.registeredName || party.user.business?.trust?.registeredName,
  );

  if (partyWithTrust) {
    return (
      partyWithTrust.user.displayName ??
      (partyWithTrust.user.business?.trust?.registeredName
        ? partyWithTrust.user.business.trust.registeredName + ' Trust'
        : unspecifiedPartyName)
    );
  }

  const partyWithBusiness = filteredParties.find((party) => party.user.business?.registeredName);

  if (partyWithBusiness) {
    return partyWithBusiness.user.business?.registeredName || unspecifiedPartyName;
  }

  const [firstParty] = filteredParties;

  if (!firstParty || !firstParty.user.firstName) {
    return unspecifiedPartyName;
  }

  const firstPartyName = `${firstParty.user.firstName} ${firstParty.user.lastName}`;

  if (filteredParties.length === 1) {
    return firstPartyName;
  }

  return `${firstPartyName} + ${filteredParties.length - 1} other`;
}

/**
 * Get all participants' formatted to display for contract select
 *
 * @param participantParties - participant parties
 * @param profile - User profile
 * @returns Formatted to displays
 */
export function getPartiesNameToDisplay(
  participantParties?: ParticipantParty[],
  profile?: UserDetail,
) {
  if (!profile || !participantParties || participantParties.length === 0) {
    return unspecifiedPartyName;
  }

  const targetParticipant = participantParties.find(
    (party) => profile?.groupRelation?.group.id === party?.userGroup?.id,
  );
  const hasBusiness = !isEmpty(targetParticipant?.businessContractInfo);
  const firstUser = targetParticipant?.usersInfo?.find(
    (user) => user.id === targetParticipant?.nominee?.id,
  );
  const usersLength = targetParticipant?.usersInfo?.length ?? 1;
  const userName =
    usersLength > 1
      ? [(firstUser?.firstName, firstUser?.lastName)].join('')
      : [firstUser?.firstName, firstUser?.lastName, ` + ${usersLength - 1} other`].join('');

  switch (targetParticipant?.payType) {
    case PayType.PAYER: {
      return hasBusiness ? targetParticipant.displayName : userName;
    }

    case PayType.PAYEE: {
      return targetParticipant.displayName ?? userName;
    }

    default: {
      return unspecifiedPartyName;
    }
  }
}

/**
 * Get account applied status
 *
 * @param party - Contract party
 * @returns Account applied status
 */
export function getAccountApplied(party?: Participant | null) {
  if (!party) {
    return false;
  }

  if (party.user.trust) {
    return !isEmpty(party.user.trust.account) ?? false;
  }

  if (party.user.business?.trust) {
    return !isEmpty(party.user.business.trust.account) ?? false;
  }

  if (party.user.business) {
    return !isEmpty(party.user.business.account) ?? false;
  }

  if (party.user.account) {
    return !isEmpty(party.user.account) ?? false;
  }

  return false;
}

/**
 * Get account provisioned status
 *
 * @param partycipantParty - Contract participant party
 * @returns Account provisioned status
 */
export function getAccountProvisioned(partycipantParty?: ParticipantParty | null) {
  if (!partycipantParty) {
    return false;
  }

  return partycipantParty?.account?.isProvisioned ?? false;
}

/**
 * Format contract party to invitee
 *
 * @param party - Contract party
 * @returns Formatted invitee
 */
export function formatPartyToInvitee(party?: Participant | null) {
  const partyInfo = {
    firstName: party?.user.firstName ?? '',
    lastName: party?.user.lastName ?? '',
    email: party?.user.email ?? '',
    confirmEmail: party?.user.email ?? '',
  };
  if (!party) {
    return {
      businessId: '',
      businessName: '',
      type: PartyType.PAYEE,
      address: defaultAddress,
      partyInfo,
    };
  }

  if (party.user.trust) {
    return {
      businessId: party.user.trust?.id,
      businessName: party.user.trust.registeredName ?? '',
      type: party.partyType ?? PartyType.PAYEE,
      address: party.user.trust.registeredAddress ?? defaultAddress,
      partyInfo,
      displayName: party.user.trust.displayName ?? '',
    };
  }

  if (party.user.business?.trust) {
    return {
      businessId: party.user.business.trust?.id,
      businessName: party.user.business.trust.registeredName ?? '',
      type: party.partyType ?? PartyType.PAYEE,
      address: party.user.business.trust.registeredAddress ?? defaultAddress,
      partyInfo,
      displayName: party.user.business.trust.displayName ?? '',
    };
  }

  if (party.user.business) {
    return {
      businessId: party.user.business?.id,
      businessName: party.user.business.registeredName ?? '',
      type: party.partyType ?? PartyType.PAYEE,
      address: party.user.business.registeredAddress ?? defaultAddress,
      partyInfo,
      displayName: party.user.business.displayName ?? '',
    };
  }

  return {
    businessId: '',
    businessName: '',
    type: PartyType.PAYEE,
    address: defaultAddress,
    partyInfo,
  };
}

/**
 * Check if party is trust or business.
 *
 * @param party - Contract party
 * @returns True if party is trust or business
 */
export function isPartyTrustOrBusiness(party: Participant): boolean {
  return Boolean(
    party.user.trust?.registeredName ??
      party.user.business?.trust?.registeredName ??
      party.user.business?.registeredName,
  );
}

/**
 * Get first payer from contract participants.
 *
 * @param participants All contract participants
 * @returns First payer or null
 */
export function getPayer(participants: Participant[] = []) {
  return participants.find((participant) => participant.partyType === PartyType.PAYER) ?? null;
}

/**
 * Get first payee from contract participants.
 *
 * @param participants All contract participants
 * @returns First payer or null
 */
export function getPayee(participants: Participant[] = []) {
  return participants.find((participant) => participant.partyType === PartyType.PAYEE) ?? null;
}

/**
 * Get first inviter from contract participants.
 *
 * @param participants All contract participants
 * @returns First inviter or null
 */
export function getInviter(participants: Participant[] = []) {
  return (
    participants.find(
      (participant) =>
        participant.partyRole === PartyRole.INVITER &&
        [PartyType.PAYEE, PartyType.PAYER].includes(participant.partyType),
    ) ?? null
  );
}

/**
 * Get first invitee from contract participants.
 *
 * @param participants All contract participants
 * @returns First invitee or null
 */
export function getInvitee(participants: Participant[] = []) {
  return (
    participants.find(
      (participant) =>
        participant.partyRole === PartyRole.INVITEE &&
        [PartyType.PAYEE, PartyType.PAYER].includes(participant.partyType),
    ) ?? null
  );
}

/**
 * Get first inviteeParty from contract participant parties.
 *
 * @param parties - All contract participant parties
 * @returns First invitee or null
 */
export function getInviteeParty(parties: ParticipantParty[] = []) {
  return (
    parties.find(
      (participant) =>
        participant.invitationType === PartyRole.INVITEE &&
        [PartyType.PAYEE, PartyType.PAYER].includes(participant.payType),
    ) ?? null
  );
}

/**
 * Get first inviterParty from contract participant parties.
 *
 * @param parties All contract participant parties
 * @returns First invitee or null
 */
export function getInviterParty(parties: ParticipantParty[] = []) {
  return (
    parties.find(
      (participant) =>
        participant.invitationType === PartyRole.INVITER &&
        [PartyType.PAYEE, PartyType.PAYER].includes(participant.payType),
    ) ?? null
  );
}

/**
 * Get payee party from contract participants.
 *
 * @param participants All contract participants
 * @returns First payer or null
 */
export function getPayeeParty(participants: ParticipantParty[] = []) {
  return participants.find((participant) => participant.payType === PayType.PAYEE) ?? null;
}

/**
 * Get payer party from contract participants.
 *
 * @param participants All contract participants
 * @returns First payer or null
 */
export function getPayerParty(participants: ParticipantParty[] = []) {
  return participants.find((participant) => participant.payType === PayType.PAYER) ?? null;
}

/**
 * Get first superintendent from contract participants.
 *
 * @param participants All contract participants
 * @returns First superintendent or null
 */
export function getSuperintendentParty(participants: ParticipantParty[] = []) {
  return participants.find((participant) => participant.payType === PayType.INTERMEDIARY) ?? null;
}

/**
 * Get two contract parties based on current user ID.
 *
 * @param participantParties - All participant parties
 * @param userId - Current user ID
 * @returns Current party and the other party
 */
export function getContractParticipantParties(
  participantParties: ParticipantParty[] = [],
  userId?: string,
) {
  const theParty = participantParties.find((party) => party.userGroup?.id === userId);

  if (!theParty) {
    return {};
  }

  const { payType } = theParty;
  const theOtherPartyType = payType === PayType.PAYEE ? PayType.PAYER : PayType.PAYEE;

  const theOtherParty = participantParties.find(
    (party) => party.userGroup?.id !== userId && party.payType === theOtherPartyType,
  );

  return {
    theParty,
    theOtherParty,
  };
}

/**
 * Get last reviewer in contract participant parties.
 *
 * @param participantParties - All participant parties
 * @returns Last reviewer participant
 */
export function getLastReviewParty(participantParties: ParticipantParty[]) {
  return (
    sortBy(participantParties, (party) => {
      return party.snapshotStatus?.createdAt
        ? -utc2Mel(party.snapshotStatus.createdAt).valueOf()
        : 0;
    }).at(0) ?? null
  );
}

/**
 *
 * @param participants participants
 * @param partyType partyType
 * @returns filtered participants
 */
export function getAdditionalParties(
  participants: ContractParticipant[],
  partyType = PartyType.ADDITIONAL_PAYEE,
) {
  return participants?.filter((participant) => participant.partyType === partyType) ?? [];
}

/**
 *
 * @param party party
 * @param contractType contractType
 * @returns contract role
 */
export function getPartyRole(contractType: ContractType, party: ParticipantParty | null) {
  if (!party) {
    return ContractRole.BUILDER;
  }
  if (contractType === ContractType.SUBCONTRACT) {
    if (party.payType === PayType.PAYER) {
      return ContractRole.BUILDER;
    }
    return ContractRole.SUBCONTRACTOR;
  }
  if (contractType === ContractType.SUPPLY_CONTRACT) {
    if (party.payType === PayType.PAYER) {
      return ContractRole.BUILDER;
    }
    return ContractRole.SUPPLIER;
  }
  if (party.payType === PayType.PAYER) {
    return ContractRole.PRINCIPAL;
  }
  if (party.payType === PayType.INTERMEDIARY) {
    return ContractRole.SUPERINTENDENT;
  }
  return ContractRole.BUILDER;
}
