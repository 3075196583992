import { browserEnvs } from '@paid-ui/env';
import { asciiPattern } from '@paid-ui/regexps/ascii';
import axios, { AxiosError, type AxiosRequestConfig } from 'axios';
import ExpiryMap from 'expiry-map';
import { isEmpty } from 'lodash';
import Router from 'next/router';
import memoize from 'p-memoize';

const { apiBaseUrl } = browserEnvs;

type RefreshTokenResponse = {
  data: {
    access_token: string;
  };
};

const publicApis = new Set([
  '/business/abn-check',
  '/business/acn-check',
  '/users/check',
  '/users/complete-signup',
  '/validate/reset-password',
  '/validate/send-confirmation-email-with-code',
  '/validate/send-join-email',
  '/validate/send-reset-password-email',
]);

const invitedSignUpApi = /^\/users\/complete-signup\/[\dA-Za-z\-]/;

/**
 * Check if the API is public.
 *
 * @param url - API url
 * @returns True if the API is public
 */
const isPublicApi = (url?: string) => {
  if (!url) return true;
  return (
    publicApis.has(url) ||
    invitedSignUpApi.test(url) ||
    (url.startsWith('/auth') && url !== '/auth/signout')
  );
};

const timeout = 29_000;
const maxAge = 10_000;

export const request = axios.create({
  baseURL: apiBaseUrl,
  timeout,
  timeoutErrorMessage: `Request timeout in ${Math.round(timeout / 1000)} seconds.`,
  headers: {
    'Content-Type': 'application/json',
  },
});

/**
 * Refresh access token function.
 *
 * @returns New access token.
 */
export async function refreshToken() {
  try {
    const access_token = globalThis.sessionStorage.getItem('access_token');
    const { data: res } = await request.post<RefreshTokenResponse>('/auth/refresh-token', {
      access_token,
    });
    const accessToken = res.data.access_token;
    if (!accessToken) return;
    globalThis.sessionStorage.setItem('access_token', accessToken);
    return accessToken;
  } catch {
    globalThis.sessionStorage.removeItem('access_token');
    const searchParams = new URLSearchParams(globalThis.location.search);
    searchParams.set('next', globalThis.location.pathname);
    searchParams.sort();
    await Router.replace(`/signin?${searchParams.toString()}`);
  }
}

export const memoizedRefreshToken = memoize(refreshToken, {
  cache: new ExpiryMap(maxAge),
});

export const findNonASCIIChar = (
  object: Record<string, unknown> | unknown[],
): [key: string, value: string] | null => {
  const entries = Object.entries(object);

  for (const [key, value] of entries) {
    if (!value) {
      continue;
    }

    if (Array.isArray(value)) {
      const result = findNonASCIIChar(value);
      if (result) return result;
      continue;
    }

    if (typeof value === 'object') {
      const result = findNonASCIIChar(value as Record<string, unknown>);
      if (result) return result;
      continue;
    }

    if (typeof value === 'string' && !asciiPattern.test(value)) {
      return [key, value];
    }
  }

  return null;
};

export const newAbortSignal = (timeoutMs: number, reason?: string) => {
  const abortController = new AbortController();
  setTimeout(() => abortController.abort(reason), timeoutMs || 0);
  return abortController.signal;
};

request.interceptors.request.use(
  (config) => {
    if (!config.url) {
      const abortReason = 'Request url is required';
      config.signal = newAbortSignal(0, abortReason);
      return config;
    }

    if (
      !isEmpty(config.data) &&
      config.method &&
      ['post', 'put', 'patch'].includes(config.method.toLowerCase())
    ) {
      const entry = findNonASCIIChar(config.data);
      if (entry) {
        const [key, value] = entry;
        const abortReason = `Non-ASCII character "${value}" found in "${key}" field`;
        config.signal = newAbortSignal(200, abortReason);
        // TODO: use toast instead of console.error
        console.error(abortReason);
        return config;
      }
    }

    if (isPublicApi(config.url)) {
      return config;
    }

    const accessToken = globalThis.sessionStorage.getItem('access_token');

    if (!accessToken) {
      const abortReason = 'Access token is required for protected APIs';
      config.signal = newAbortSignal(0, abortReason);
      return config;
    }

    config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
  },
  (error) => Promise.reject(error instanceof Error ? error : new Error('Unknown request error.')),
);

request.interceptors.response.use(
  (response) => response,
  async (error) => {
    switch (error.response?.status) {
      case 500:
      case 501:
      case 502:
      case 503:
      case 504: {
        throw new Error('Internal server error.');
      }

      case 401: {
        const lastRequestConfig = error.config as AxiosRequestConfig & { sent: boolean };

        if (isPublicApi(lastRequestConfig.url)) {
          throw new Error(error.response?.data.message ?? 'Unknown response error.');
        }

        if (!lastRequestConfig.sent) {
          lastRequestConfig.sent = true;
          const accessToken = await memoizedRefreshToken();

          if (!accessToken) {
            globalThis.sessionStorage.removeItem('access_token');
            throw new Error('Refresh token failed, please sign in again.');
          }

          lastRequestConfig.headers = {
            ...lastRequestConfig.headers,
            Authorization: `Bearer ${accessToken}`,
          };

          return request(lastRequestConfig);
        }

        globalThis.sessionStorage.removeItem('access_token');
        throw new Error('Refresh token failed, please sign in again.');
      }

      case 403: {
        throw new Error('Forbidden.');
      }

      case 404: {
        throw error.response.config.url.includes('transaction-account/search')
          ? error.response
          : new Error('Not found.');
      }

      case 409: {
        if (error.response.config.url.includes('challenge/address')) {
          throw error.response?.data;
        }
        throw 'response' in error
          ? new Error(error.response?.data.message ?? 'Unknown response error.')
          : new Error(error instanceof AxiosError ? error.message : 'Unknown request error.');
      }

      case 422: {
        const retryAllowed: boolean = error.response?.data?.retryAllowed ?? false;
        if (retryAllowed) {
          await new Promise((resolve) => setTimeout(resolve, 5000));
          const lastRequestConfig = error.config as AxiosRequestConfig;
          return request(lastRequestConfig);
        }
        throw 'response' in error
          ? new Error(error.response?.data.message ?? 'Unknown response error.')
          : new Error(error instanceof AxiosError ? error.message : 'Unknown request error.');
      }

      default: {
        throw 'response' in error
          ? new Error(error.response?.data.message ?? 'Unknown response error.')
          : new Error(error instanceof AxiosError ? error.message : 'Unknown request error.');
      }
    }
  },
);
