import {
  plusOrMinus,
  PriceVariationMethod,
  VariationSource,
  VariationStage,
} from '@paid-ui/constants';
import type { PaymentStage, Variation, VariationItemLinkedToClaim } from '@paid-ui/types';

import { formatCurrency } from './formatter';

export type VariationFilter = 'all' | 'dangling' | 'claimed';

/**
 * Format variation item description.
 *
 * @param value - Variation item.
 * @returns Formatted variation item description.
 */
export function formatVariationDescription(value?: Variation | null): string {
  if (!value) {
    return '-';
  }

  const { description, source, variationDetails } = value;

  if (source === VariationSource.OFF_PLATFORM) {
    return description ?? '-';
  }

  const [firstVariation, ...restVariations] = variationDetails || [];

  if (!firstVariation?.description) {
    return '-';
  }

  switch (restVariations.length) {
    case 0: {
      return firstVariation.description;
    }
    case 1: {
      return `${firstVariation.description} + 1 other item`;
    }
    default: {
      return `${firstVariation.description} + ${restVariations.length} other items`;
    }
  }
}

/**
 * Format variation item linked to claim
 *
 * @param variation - Original variation item
 * @param index - index
 * @returns Variation item
 */
export function formatVariationItem(
  variation: Variation,
  index: number,
): VariationItemLinkedToClaim {
  const valueAsNumber =
    plusOrMinus[variation.totalPriceImpact ?? PriceVariationMethod.NONE] *
    (variation.totalVariationPrice ?? 0);

  return {
    id: variation.id,
    disabled: !!variation.claimId,
    label:
      variation.displayName || variation.description || `Variation ${variation.index ?? index + 1}`,
    value: formatCurrency(valueAsNumber),
    valueAsNumber,
    description: formatVariationDescription(variation),
  };
}

/**
 * Get filtered variation items
 *
 * @param variations - All variations
 * @param payment - Payment stage to filter
 * @param filter - Filter type, default to 'all'
 * @returns Filtered variation items
 */
export function getVariationItems(
  variations: Variation[] = [],
  payment: PaymentStage | null = null,
  filter: VariationFilter = 'all',
): VariationItemLinkedToClaim[] {
  const approvedVariations = variations.filter(
    (variation) => variation.variationStage === VariationStage.APPROVED,
  );

  switch (filter) {
    case 'all': {
      return approvedVariations
        .filter((variation) => !variation.claimId || variation.claimId === payment?.claim?.id)
        .map(formatVariationItem);
    }

    case 'dangling': {
      return approvedVariations.filter((variation) => !variation.claimId).map(formatVariationItem);
    }

    case 'claimed': {
      return approvedVariations
        .filter((variation) => variation.claimId && variation.claimId === payment?.claim?.id)
        .map(formatVariationItem);
    }

    default: {
      return approvedVariations.map(formatVariationItem);
    }
  }
}
