export enum AttachmentCategory {
  'SPECIFICATION' = 'SPECIFICATION',
  'DESIGN_DRAWINGS' = 'DESIGN_DRAWINGS',
  'ENGINEERING_DRAWINGS' = 'ENGINEERING_DRAWINGS',
  'FOUNDATION_REPORTS' = 'FOUNDATION_REPORTS',
  'SPECIAL_CONDITIONS' = 'SPECIAL_CONDITIONS',
  'PURCHASE_ORDER' = 'PURCHASE_ORDER',
  'LOAN_APPROVAL' = 'LOAN_APPROVAL',
  'OFF_PLATFORM' = 'OFF_PLATFORM',
  'CERTIFICATES' = 'CERTIFICATES',
  'NOTICES' = 'NOTICES',
  'CONTRACT_PRICE_BREAKDOWN' = 'CONTRACT_PRICE_BREAKDOWN',
  'CLAIM_SUBMISSION' = 'CLAIM_SUBMISSION',
  'CLAIM_ASSESSMENT' = 'CLAIM_ASSESSMENT',
  'BANK_GUARANTEE' = 'BANK_GUARANTEE',
  'PERIODIC_VARIATION' = 'PERIODIC_VARIATION',
  'SITE_INFORMATION' = 'SITE_INFORMATION',
  'PRACTICAL_COMPLETION' = 'PRACTICAL_COMPLETION',
  'DEFECTS_LIABILITY_PERIOD' = 'DEFECTS_LIABILITY_PERIOD',
  'OTHER' = 'OTHER',
}

export enum BankGuaranteeCategory {
  'PRACTICAL_COMPLETION' = 'PRACTICAL_COMPLETION',
  'DEFECTS_LIABILITY_PERIOD' = 'DEFECTS_LIABILITY_PERIOD',
}

export enum UploadStatus {
  WAITING = 'WAITING',
  UPLOADING = 'UPLOADING',
  UPLOAD_SUCCESS = 'UPLOAD_SUCCESS',
  UPLOAD_FAILED = 'UPLOAD_FAILED',
}
