import { TrustType } from '@paid-ui/constants';
import { BackgroundCheckStatus, TransactionAccountMatcherType } from '@paid-ui/enums/account';
import { BusinessStructure, BusinessType } from '@paid-ui/enums/business';
import { type FeatureItem } from '@paid-ui/enums/feature';
import { PartyType } from '@paid-ui/enums/party';
import { SignatureType } from '@paid-ui/enums/signatory';
import { CompanyDirectorsStructure, UserGroupRelation } from '@paid-ui/enums/user';
import {
  type Address,
  type GroupUser,
  type UserAccount,
  type UserBusiness,
  type UserDetail,
  type UserForTrust,
  type UserInfoForBusiness,
} from '@paid-ui/types';
import { type UserSignatory, type UserSignature } from '@paid-ui/types/signatory';
import { isEmpty } from 'lodash';
import { proxy } from 'valtio';
import { devtools } from 'valtio/utils';

import { retentionAccountManager } from './retention-account';
import { transactionAccountManager } from './transaction-account';

const initialState = {
  /** If user signed in */
  authed: false,
  /** Token saved */
  token: '',
  /** Impersonation */
  impersonation: false,
  /** User profile mapped */
  profile: undefined as UserDetail | undefined,
  /** User business mapped */
  business: undefined as UserBusiness | undefined,
  /** User trust mapped */
  trust: undefined as UserForTrust | undefined,
  /** User account mapped */
  account: undefined as UserAccount | undefined,
  /** Features that is active for user */
  features: [] as FeatureItem[],
  /** If current user is owner */
  isOwner: false,
  /** If current user has business */
  hasBusiness: false,
  /** If current user is trustee */
  isTrust: false,
  /** If current user is individual trustee */
  isIndividualTrust: false,
  /** Formatted name for display */
  displayName: '',
  /** Business type of current user */
  businessType: BusinessType.OWNER,
  /** Business structure of current business */
  businessStructure: undefined as BusinessStructure | undefined,
  /** Registered name of current user */
  registeredName: '',
  /** Registered address of current user */
  registeredAddress: undefined as Address | undefined,
  /** If registered for GST or not */
  registeredForGst: true,
  /** ABN of current business */
  abn: '',
  /** ACN of current company */
  acn: '',
  /** Contact number of business */
  contactNumber: '',
  /** Builder licence number */
  builderLicenceNumber: '',
  /** Early release discount rate for current user */
  earlyReleaseDiscountRate: undefined as number | undefined,
  /** If current user can create project and account or not */
  provideBuildingServices: false,
  /** If current user's business setup is completed */
  businessSetupCompleted: false,
  /** If current user's transaction account is provisioned */
  provisioned: false,
  /** Background check status of current user */
  backgroundCheckStatus: BackgroundCheckStatus.UNKNOWN,
  /** If current user's transaction account is ready */
  isAccountReady: false,
  /** If background check or account creation in progress or not */
  isAccountRequestPending: false,
  /** If background check or account creation failed or not */
  isAccountRequestFailed: false,
  /** If Kyc process requested or not (requested, pass, result_ready, failed) */
  isKycRequested: false,
  /** If Kyc process requesting or not (requested, pass, result_ready) */
  isKycNoFailRequested: false,
  /** If Kyb process requested without fail or not */
  isKybRequested: false,
  /** If Kyb process requested without fail or not */
  isKybNoFailRequested: false,
  /** If Kyc/Kyb process requested or not */
  isKybKycRequested: false,
  /** If Kyc/Kyb process requested without fail or not */
  isKybKycNoFailRequested: false,
  /** Default view as of current user */
  defaultViewAs: PartyType.PAYER,
  /** Has multiple users */
  hasMultipleUsers: false,
  /** All additional business users including me */
  businessUsers: [] as UserInfoForBusiness[],
  /** Me in additional business users */
  currentUser: undefined as UserInfoForBusiness | undefined,
  /** Additional business users excluding me */
  additionalUsers: [] as UserInfoForBusiness[],
  /** User profile requires edit */
  requirePersonalEdit: false,
  /** Business profile requires edit */
  requireBusinessEdit: false,
  /** Business profile requires edit for primary contract */
  requireBusinessEditForPrime: false,
  /** Business profile requires additional users */
  requireAdditionalUsersForBusiness: false,
  /** Trustee profile requires edit */
  requireTrusteeEdit: false,
  /** Trustee profile requires additional users */
  requireAdditionalUsersForTrustee: false,
  /** Trustee profile requires edit without addr */
  requireTrusteeEditWithoutAddr: false,
  /** Force update flag */
  forceUpdate: 0,
  /** Show retention account flag */
  retentionEnabled: false,
  /** User group id */
  userGroupId: '',
  /** Trustee group id */
  trusteeGroupId: '',
  /** User group relation */
  userGroupRelation: UserGroupRelation.UNSPECIFIED,

  /** User signatures */
  signature: null as UserSignature | null,
  /** Initial version of user signature */
  initialSignature: null as UserSignature | null,
  /** All signatories */
  signatories: [] as UserSignatory[],
  /** Required number of signatories */
  requiredGroupSignatories: 0,
};

export const userManager = proxy(initialState);

devtools(userManager, {
  name: 'User',
  enabled: false,
});

export const signIn = (token: string) => {
  userManager.authed = true;
  userManager.token = token;
};

const requestedCheckStatus = new Set([
  BackgroundCheckStatus.REQUESTED,
  BackgroundCheckStatus.RESULT_READY,
  BackgroundCheckStatus.PASS,
  BackgroundCheckStatus.FAIL,
]);

const noFailRequestedCheckStatus = new Set([
  BackgroundCheckStatus.REQUESTED,
  BackgroundCheckStatus.RESULT_READY,
  BackgroundCheckStatus.PASS,
]);

const toFixed = (value?: number) => (value ? Number((value * 100).toFixed(2)) : undefined);

const getUserSignatory = (
  user: UserInfoForBusiness | GroupUser,
  isCurrentUser: boolean,
): UserSignatory => ({
  id: user.id || null,
  email: user.email,
  mobile: user.mobile ?? '',
  firstName: user.firstName,
  lastName: user.lastName,
  fullName: [user.firstName, user.lastName].join(' '),
  isCurrentUser,
  selected: isCurrentUser,
});

const getUserSignatories = (users: UserInfoForBusiness[] | GroupUser[], email: string) => {
  return users.map((user) => getUserSignatory(user, user.email === email));
};

export const saveProfile = (profile: UserDetail) => {
  const {
    firstName,
    lastName,
    mobile,
    homeAddress,
    impersonation,
    backgroundCheck,
    business,
    account: individualAccount,
    features = [],
    displayName: individualDisplayName,
    retentionEnabled,
    groupRelation,
    userSignatures,
  } = profile;

  const kycCheckStatus = backgroundCheck?.status;
  const kybCheckStatus = business?.backgroundCheck?.status;
  const isTrust = business?.businessStructure === BusinessStructure.TRUST;

  const trust =
    business?.businessStructure === BusinessStructure.TRUST ? business?.trustees?.[0] : undefined;

  const hasMultipleDirector =
    trust?.companyDirectorsStructure === CompanyDirectorsStructure.MULTI_DIRECTORS ||
    business?.companyDirectorsStructure === CompanyDirectorsStructure.MULTI_DIRECTORS;
  const account =
    groupRelation?.group.account ?? individualAccount ?? trust?.account ?? business?.account;
  const isOwner = !trust?.provideBuildingServices && !business?.provideBuildingServices;
  const hasBusiness = Boolean(trust || business);

  const isIndividualTrust = business?.trustType === TrustType.INDIVIDUAL;
  const displayName =
    trust?.displayName ??
    business?.displayName ??
    individualDisplayName ??
    [firstName, lastName].filter(Boolean).join(' ');
  const businessType = business?.businessType ?? BusinessType.OWNER;
  const businessStructure = business?.businessStructure ?? trust?.businessStructure;
  const registeredName = business?.registeredName ?? '';
  const registeredAddress = business?.registeredAddress;
  const registeredForGst = business?.registeredForGst ?? true;
  const contactNumber = business?.contactNumber ?? '';
  const abn = trust?.abn ?? business?.abn ?? '';
  const acn = trust?.acn ?? business?.acn ?? '';
  const builderLicenceNumber = trust?.builderLicenceNumber ?? business?.builderLicenceNumber ?? '';
  const earlyReleaseDiscountRate = toFixed(
    trust?.earlyReleaseDiscountRate ?? business?.earlyReleaseDiscountRate,
  );
  const provideBuildingServices =
    (isTrust
      ? business?.provideBuildingServices
      : trust?.provideBuildingServices ?? business?.provideBuildingServices) ?? false;
  const businessSetupCompleted =
    trust?.businessSetupCompleted ?? business?.businessSetupCompleted ?? false;
  const provisioned = account?.provisioned ?? false;
  const isAccountRequestPending = hasBusiness
    ? kycCheckStatus === BackgroundCheckStatus.REQUESTED ||
      kybCheckStatus === BackgroundCheckStatus.REQUESTED ||
      (Boolean(account) && !provisioned)
    : kycCheckStatus === BackgroundCheckStatus.REQUESTED || (Boolean(account) && !provisioned);
  const isAccountRequestFailed = hasBusiness
    ? kycCheckStatus === BackgroundCheckStatus.FAIL || kybCheckStatus === BackgroundCheckStatus.FAIL
    : kycCheckStatus === BackgroundCheckStatus.FAIL;
  const isAccountReady = provisioned;

  const isKycRequested = !!kycCheckStatus && requestedCheckStatus.has(kycCheckStatus);
  const isKycNoFailRequested = !!kycCheckStatus && noFailRequestedCheckStatus.has(kycCheckStatus);
  const isKybRequested = !!kybCheckStatus && requestedCheckStatus.has(kybCheckStatus);
  const isKybNoFailRequested = !!kybCheckStatus && noFailRequestedCheckStatus.has(kybCheckStatus);
  const isKybKycRequested = hasBusiness ? isKycRequested || isKybRequested : isKycRequested;
  const isKybKycNoFailRequested = hasBusiness
    ? isKycNoFailRequested || isKybNoFailRequested
    : isKycNoFailRequested;
  const backgroundCheckStatus = kybCheckStatus ?? kycCheckStatus ?? BackgroundCheckStatus.UNKNOWN;

  const defaultViewAs = isOwner ? PartyType.PAYER : PartyType.PAYEE;
  const users = isEmpty(business?.trustees) ? business?.officers : business?.trustees;
  const businessUsers = (trust?.officers ?? users ?? []) as UserInfoForBusiness[];
  const groupUsers = groupRelation?.group?.users ?? [];

  const currentUser = businessUsers.find((user) => user.id === profile.id);
  const additionalUsers = businessUsers.filter((user) => user.id !== profile.id);
  const additionalGroupUsers = groupUsers?.filter((user) => user.id !== profile.id);
  const additionals = additionalGroupUsers.length > 0 ? additionalGroupUsers : additionalUsers;

  userManager.signature =
    userSignatures.find((s) => s.signatureType === SignatureType.FULL_NAME) ?? null;
  userManager.initialSignature =
    userSignatures.find((s) => s.signatureType === SignatureType.INITIAL) ?? null;

  const signatories = getUserSignatories(
    groupUsers.length > 0 ? groupUsers : businessUsers,
    profile.email,
  );

  userManager.signatories = signatories;
  userManager.requiredGroupSignatories =
    groupRelation?.group.requiredGroupSignatories ?? signatories.length;

  userManager.impersonation = impersonation ?? false;
  userManager.hasMultipleUsers = hasMultipleDirector;
  userManager.isKycRequested = isKycRequested;
  userManager.isKycNoFailRequested = isKycNoFailRequested;
  userManager.isKybRequested = isKybRequested;
  userManager.isKybNoFailRequested = isKybNoFailRequested;
  userManager.userGroupId = groupRelation?.group.id ?? '';
  userManager.trusteeGroupId = groupRelation?.group?.linkedGroups?.[0]?.group?.id ?? '';
  userManager.userGroupRelation = groupRelation?.relation ?? UserGroupRelation.UNSPECIFIED;
  userManager.profile = profile;
  userManager.business = business;
  userManager.trust = trust;
  userManager.account = account;
  userManager.features = features.map(({ name }) => name);
  userManager.isOwner = isOwner;
  userManager.hasBusiness = hasBusiness;
  userManager.isTrust = isTrust;
  userManager.isIndividualTrust = isIndividualTrust;
  userManager.displayName = displayName;
  userManager.businessType = businessType;
  userManager.businessStructure = businessStructure;
  userManager.registeredName = registeredName;
  userManager.registeredAddress = registeredAddress;
  userManager.registeredForGst = registeredForGst;
  userManager.contactNumber = contactNumber;
  userManager.abn = abn;
  userManager.acn = acn;
  userManager.builderLicenceNumber = builderLicenceNumber;
  userManager.earlyReleaseDiscountRate = earlyReleaseDiscountRate;
  userManager.provideBuildingServices = provideBuildingServices;
  userManager.businessSetupCompleted = businessSetupCompleted;
  userManager.provisioned = provisioned;
  userManager.backgroundCheckStatus = backgroundCheckStatus;
  userManager.isAccountRequestPending = isAccountRequestPending;
  userManager.isAccountRequestFailed = isAccountRequestFailed;
  userManager.isAccountReady = isAccountReady;
  userManager.isKybKycRequested = isKybKycRequested;
  userManager.isKybKycNoFailRequested = isKybKycNoFailRequested;
  userManager.defaultViewAs = defaultViewAs;

  userManager.businessUsers = businessUsers;
  userManager.currentUser = currentUser;
  userManager.additionalUsers = additionals;
  userManager.retentionEnabled = !!retentionEnabled;

  userManager.requirePersonalEdit = !firstName || !lastName || !mobile || !homeAddress;
  const requireAdditionalUsersForBusiness =
    !businessSetupCompleted &&
    businessStructure === BusinessStructure.COMPANY &&
    hasMultipleDirector &&
    additionalUsers.length === 0 &&
    additionalGroupUsers.length === 0;
  const requireBusinessEdit = hasBusiness
    ? !registeredName ||
      !registeredAddress ||
      !abn ||
      (businessStructure === BusinessStructure.COMPANY && !acn) ||
      requireAdditionalUsersForBusiness
    : false;
  userManager.requireBusinessEdit = requireBusinessEdit;
  userManager.requireAdditionalUsersForBusiness = requireAdditionalUsersForBusiness;
  userManager.requireBusinessEditForPrime =
    provideBuildingServices && !builderLicenceNumber && businessType === BusinessType.BUILDER;

  const requireAdditionalUsersForTrustee =
    isTrust &&
    !isIndividualTrust &&
    !trust?.businessSetupCompleted &&
    hasMultipleDirector &&
    !isKybKycRequested &&
    additionalUsers.length === 0 &&
    additionalGroupUsers.length === 0;

  userManager.requireTrusteeEditWithoutAddr =
    isTrust && !isIndividualTrust
      ? !trust?.registeredName ||
        (trust.businessStructure === BusinessStructure.COMPANY && !acn) ||
        requireAdditionalUsersForTrustee
      : false;

  userManager.requireTrusteeEdit =
    isTrust && !isIndividualTrust
      ? !trust?.registeredName ||
        !trust.registeredAddress ||
        (trust.businessStructure === BusinessStructure.COMPANY && !acn) ||
        requireAdditionalUsersForTrustee
      : false;
  userManager.requireAdditionalUsersForTrustee = requireAdditionalUsersForTrustee;
  if (account?.provisioned) {
    const mainAccount = account.matchers.find(
      (matcher) => matcher.type === TransactionAccountMatcherType.MAIN,
    );
    if (mainAccount) {
      transactionAccountManager.id = mainAccount.id;
      transactionAccountManager.bsb = mainAccount.bsb;
      transactionAccountManager.accountNumber = mainAccount.accountNumber;
    }

    const retentionAccount = account.matchers.find(
      (matcher) => matcher.type === TransactionAccountMatcherType.RETENTION,
    );
    if (retentionAccount) {
      retentionAccountManager.id = retentionAccount.id;
      retentionAccountManager.bsb = retentionAccount.bsb;
      retentionAccountManager.accountNumber = retentionAccount.accountNumber;
    }
  }
};

export const reset = () => {
  Object.assign(userManager, initialState);
};

export const setTransactionAccountReady = () => {
  userManager.isAccountReady = true;
};

export const reloadProfile = () => {
  const { profile, forceUpdate } = userManager;
  if (!profile) return;
  userManager.forceUpdate = forceUpdate > 100 ? 1 : forceUpdate + 1;
};
