export enum CountryCode {
  AU = 'AU',
}

export enum AddressState {
  VIC = 'VIC',
  NSW = 'NSW',
  WA = 'WA',
  SA = 'SA',
  NT = 'NT',
  ACT = 'ACT',
  QLD = 'QLD',
  TAS = 'TAS',
}

export const states: Record<CountryCode, Array<[AddressState, string]>> = {
  [CountryCode.AU]: [
    [AddressState.VIC, 'Victoria'],
    [AddressState.NSW, 'New South Wales'],
    [AddressState.WA, 'Western Australia'],
    [AddressState.SA, 'South Australia'],
    [AddressState.NT, 'Northern Territory'],
    [AddressState.ACT, 'Australian Capital Territory'],
    [AddressState.QLD, 'Queensland'],
    [AddressState.TAS, 'Tasmania'],
  ],
};

export const defaultAddress = {
  unitNumber: '',
  streetNumber: '',
  streetName: '',
  suburb: '',
  postcode: '',
  state: AddressState.VIC,
  country: CountryCode.AU,
  manual: false,
};
